<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<script>
  export default {
    mounted () {
            this.openFullScreen()
    },
    methods: {
       openFullScreen() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          loading.close();
        }, 5000);
      },
    }
  }
</script>

<style lang="scss">
* {
  margin:0;
  padding:0;
}
html,body,#app {
  width: 100%;
  height: 100%;
}
html {
  font-size: 13.333333333vw;
}
body {
  font-size: 16px; 
}

/*进度条颜色*/
#nprogress .bar {
    background: blue !important;
  }

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


</style>
